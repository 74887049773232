import { auth } from "../../utils/init-firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import axios from "axios";
import ls from "localstorage-slim";
import { postApi, putApi, getApi } from "../../api";

export const isMenuActive = (toggler) => (dispatch) => {
  return dispatch({
    type: "menuActive",
    payload: toggler,
  });
};

export const auth_signin = (email, password) => (dispatch) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const auth_signup = (email, password) => (dispatch) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const getAll = () => (dispatch) => {
  dispatch({
    type: "isLoading",
    payload: true,
  });
  const currentToken = localStorage.getItem("Token");
  const token = JSON.parse(currentToken);
  axios
    .get(`${process.env.REACT_APP_SERVER_URL}/halo/api/v1/category?page=1`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: "firstQuestion",
        payload: response.data.data.list,
      });
      dispatch({
        type: "isLoading",
        payload: false,
      });
    })
    .catch(function (error) {
      // console.log(error);
      dispatch({
        type: "errorMassage",
        payload: error,
      });
      dispatch({
        type: "isLoading",
        payload: false,
      });
    });
};

export const updateUser = (objData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "isLoading",
        payload: true,
      });
      const currentToken = localStorage.getItem("Token");
      const token = JSON.parse(currentToken);
      let { data } = await putApi(
        `${process.env.REACT_APP_SERVER_URL}/halo/api/v1/user`,
        objData,
        token
      );
      // console.log("user updateUser1 response", data)
      if (data.isSuccess) {
        ls.clear();
        ls.set("userData", data);

        dispatch({
          type: "isLoading",
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: "errorMassage",
          payload: data.message,
        });
        dispatch({
          type: "isLoading",
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      dispatch({
        type: "errorMassage",
        payload: message,
      });
      dispatch({
        type: "isLoading",
        payload: false,
      });

      return Promise.reject({ status: false, message });
    }
  };
};

export const createVisit = (objData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "isLoading",
        payload: true,
      });
      const currentToken = localStorage.getItem("Token");
      const token = JSON.parse(currentToken);
      let { data } = await postApi(
        `${process.env.REACT_APP_SERVER_URL}/halo/api/v1/visit`,
        objData,
        token
      );
      localStorage.setItem("restore", JSON.stringify(data?.data?.VisitId));
      if (data.isSuccess) {
        dispatch({
          type: "startquestion",
          payload: data.data,
        });
        dispatch({
          type: "isLoading",
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: "errorMassage",
          payload: data.message,
        });
        dispatch({
          type: "isLoading",
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      // console.log(message);
      dispatch({
        type: "errorMassage",
        payload: message,
      });
      dispatch({
        type: "isLoading",
        payload: false,
      });
      return Promise.reject({ status: false, message });
    }
  };
};

export const createAnswer = (objData) => {
  const newdata = {
    selectedOptionId: objData?.selectedOptionId,
    filters: objData?.filters,
  };
  return async (dispatch) => {
    try {
      dispatch({
        type: "isLoading",
        payload: true,
      });
      const currentToken = localStorage.getItem("Token");
      const token = JSON.parse(currentToken);
      let { data } = await putApi(
        `${process.env.REACT_APP_SERVER_URL}/halo/api/v1/visit/${objData?.VisitId}/answers/${objData?.AnswerId}`,
        newdata,
        token
      );
      localStorage.setItem("restore", JSON.stringify(data?.data?.VisitId));
      if (data.isSuccess) {
        dispatch({
          type: "startquestion",
          payload: data.data,
        });
        dispatch({
          type: "isLoading",
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: "isLoading",
          payload: false,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      // console.log(message);
      dispatch({
        type: "isLoading",
        payload: false,
      });
      return Promise.reject({ status: false, message });
    }
  };
};

export const onPageReload = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "isLoading",
        payload: true,
      });
      const currentToken = localStorage.getItem("Token");
      const token = JSON.parse(currentToken);
      const currentQuestion = localStorage.getItem("restore");
      const restoreQestion = JSON.parse(currentQuestion);
      let { data } = await getApi(
        `${process.env.REACT_APP_SERVER_URL}/halo/api/v1/visit/${restoreQestion}/inprogress`,
        token
      );
      if (data.isSuccess) {
        dispatch({
          type: "startquestion",
          payload: data.data,
        });
        dispatch({
          type: "isLoading",
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: "isLoading",
          payload: false,
        });
        dispatch({
          type: "errorMassage",
          payload: data.message,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      // console.log(message);
      dispatch({
        type: "errorMassage",
        payload: message,
      });
      dispatch({
        type: "isLoading",
        payload: false,
      });
      return Promise.reject({ status: false, message });
    }
  };
};

export const onPageBack = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "isLoading",
        payload: true,
      });
      const currentToken = localStorage.getItem("Token");
      const token = JSON.parse(currentToken);
      const VisiT_ID = localStorage.getItem("restore");
      const restoreQestion = JSON.parse(VisiT_ID);
      let { data } = await getApi(
        `${process.env.REACT_APP_SERVER_URL}/halo/api/v1/visit/${restoreQestion}/back`,
        token
      );
      if (data.isSuccess) {
        dispatch({
          type: "startquestion",
          payload: data.data,
        });
        dispatch({
          type: "isLoading",
          payload: false,
        });
        return Promise.resolve({ data: data });
      } else {
        dispatch({
          type: "isLoading",
          payload: false,
        });
        dispatch({
          type: "errorMassage",
          payload: data.message,
        });
        return Promise.resolve({ status: false });
      }
    } catch ({ message }) {
      // console.log(message);
      dispatch({
        type: "errorMassage",
        payload: message,
      });
      dispatch({
        type: "isLoading",
        payload: false,
      });
      return Promise.reject({ status: false, message });
    }
  };
};


import React, { useEffect, useState } from "react";
// import Roundtop from './assets/images/round-top.png';
import close from "./assets/images/close.png";
// import righticon from './assets/images/right-icon.png';
import menulogo from "./assets/images/menu-logo.png";
import muteicon from "./assets/images/muteicon.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../state/index";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import ls from "localstorage-slim";
// import axios from "axios";
import { authCheck } from "../utils/auth";
// import { Spinner} from "react-bootstrap";

function WhatboughtScreen() {
  const dispatch = useDispatch();
  // const check = useSelector(state => state.fireauth.userData);
  const list = [{text: "Question 1"}, {text: "Question 2"}]
  
  // useSelector((state) => state.allApiCalls.question);
  const loading = useSelector((state) => state.allApiCalls.Loading);
  const { getAll, createVisit } = bindActionCreators(actionsCreators, dispatch);
  const navigate = useNavigate();
  const user = 
  { data: {
    userId: "12312",
    categoryId: 0,
    name: "Juan Joe",
    dateOfBirth: "January 1, 1996",
  }}
  // ls.get("userData", { decrypt: true });
  const userId = user?.data?.id;
  const userName = user?.data?.name;
  const userBday = user?.data?.dateOfBirth;
  const [data, setdata] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    // if (!authCheck()) {
      // navigate("/");
    // }
  },[]);

  useEffect(() => {
    getAll();
  }, []);

  async function handleClickOpen(_id) {
    navigate("/questions");
    const data = {
      userId: "12312",
      categoryId: 0,
      name: "Juan Joe",
      dateOfBirth: "January 1, 1996",
    };
    if (userName !== "" && userBday !== "") {
      try {
        setError("");
        await createVisit(data);
        navigate("/questions");
      } catch {
        setError("Some Error Accrued Please Try Again");
      }
    } else {
      navigate("/name-and-birth", { state: { categoryId: _id } });
    }
  }

  return (
    <section class="background-bg d-flex align-items-center">
      {/* <div class="image-rounds disnoneselect">
            <a href="#."> <img src={Roundtop} alt="" /></a>
        </div> */}

      <div class="container">
        <div class="curve-box-signup selecttabs d-flex align-items-center">
          <Link to="/">
            <div class="close-btn">
              <a href="">
                <img src={close} alt="" />{" "}
              </a>
            </div>
          </Link>

          {/* <div class="nextbtn">
                    <img src={righticon} alt="" />
                </div> */}
          <div class="row w100 justify-content-center">
            <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12">
              <div class="container-fluid lastchilds">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="headingwithlogo">
                      <img src={menulogo} alt="" />
                      <h3>
                        {error ? (
                          <>{error}</>
                        ) : (
                          "Hello! What brought you here today?"
                        )}{" "}
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="row mt-40c">
                  {loading ? (
                    <div className="question-spinner">
                      <Spinner animation="border" variant="light" />
                    </div>
                  ) : (
                    <>
                      {list &&
                        list.map((row) => (
                          <div key={row.id} class="col-lg-6 col-md-6 col-sm-12">
                            <div
                              onClick={() => handleClickOpen(row.id)}
                              class="select-button"
                            >
                              <a class="selectstyle">{row.text}</a>
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div class="dot-style">
                    <span></span>
                    <span></span>
                    <span></span>
                </div> */}
        </div>
      </div>

      <div class="mute-rounds">
        <a href="#.">
          {" "}
          <img src={muteicon} alt="" /> Mute
        </a>
      </div>
    </section>
  );
}

export default WhatboughtScreen;

import React, { useState } from "react";
import close from "./assets/images/close.png";
import mobilelogo from "./assets/images/mobile-logo.png";
import logo37 from "./assets/images/logo37.png";
import muteicon from "./assets/images/muteicon.png";
import { Alert, Spinner } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../state/index";
import { useDispatch, useSelector } from "react-redux";
import ls from "localstorage-slim";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";

const styles = {
  fadeIn: {
    animation: "x 0.3s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
};

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.allApiCalls.isActive);

  const { auth_signin, isMenuActive } = bindActionCreators(
    actionsCreators,
    dispatch
  );

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    navigate("/start");
    e.preventDefault();
    setLoading(true);
    try {
      await auth_signin(email, password).then(function (response) {
        localStorage.setItem(
          "Token",
          JSON.stringify(response.user.accessToken)
        );
        _signin(response.user.accessToken);
        dispatch({
          type: "authSignIn",
          payload: response.user.accessToken,
        });
      });
    } catch (error) {
      localStorage.clear();
      setError("Email or Password incorrect");
      setLoading(false);
    }
  };

  const _signin = (data) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/halo/api/v1/user/signin`, {
        headers: {
          authorization: `Bearer ${data}`,
        },
      })
      .then(function (response) {
        if (response.data.isSuccess) {
          ls.set("userData", response.data);
          handeltoggle();
          navigate("/");
          setLoading(false);
          dispatch({
            type: "SignIn",
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        localStorage.clear();
        ls.clear();
        setError("Something went wrong please try again");
        setLoading(false);
      });
  };

  const handeltoggle = () => {
    isMenuActive(!isActive);
  };

  return (
    <StyleRoot>
      <section class="background-bg d-flex align-items-center">
        <div class="container">
          <div
            class="curve-box-signin d-flex align-items-center"
            style={styles.fadeIn}
          >
            <Link to="/">
              <div class="close-btn">
                <a href="#.">
                  <img src={close} alt="" />{" "}
                </a>
              </div>
            </Link>
            <div class="row w100">
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"></div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-9 col-md-9 col-sm-12">
                      <div class="logo-mobile">
                        <img src={mobilelogo} alt="" />
                      </div>
                      <div class="formsignup padding-lefts">
                        <h2>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.{" "}
                        </h2>
                        <a
                          className="anchertag"
                          onClick={() => {
                            navigate("/signup");
                          }}
                        >
                          Don't have an account? Sign-Up
                        </a>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <form onSubmit={(event) => handleSubmit(event)}>
                          <div class="form-group">
                            <input
                              type="email"
                              placeholder="Email Address"
                              onChange={(e) => setemail(e.target.value)}
                              class="form-control"
                            />
                          </div>
                          <div class="form-group">
                            <input
                              type="password"
                              placeholder="Password"
                              onChange={(e) => setpassword(e.target.value)}
                              class="form-control"
                            />
                          </div>

                          <button
                            class="btn btn-submit btncolor mt-4"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  variant="light"
                                />
                                &nbsp; Loading...
                              </>
                            ) : (
                              "Sign-in"
                            )}{" "}
                          </button>

                          <div class="logo-signin mt-3">
                            <img src={logo37} alt="" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mute-rounds">
          <a href="#.">
            {" "}
            <img src={muteicon} alt="" /> Mute
          </a>
        </div>
      </section>
    </StyleRoot>
  );
}

export default Login;

import React,{useEffect} from "react";
import round from "./assets/images/round-top.png";
import close from "./assets/images/close.png";
import righticon from "./assets/images/right-icon.png";
import lefticon from "./assets/images/left-icon.png";
import whitelogos from "./assets/images/white-logos.png";
import windyclinical from "./assets/images/windy-clinical.png";
import cindy from "./assets/images/cindy.png";
import mindy from "./assets/images/mindy.png";
import muteicon from "./assets/images/muteicon.png";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../state/index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { authCheck } from "../utils/auth";

function Professionals() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const list = {
    VisitId : "visitid123",
    NextQuestion: {
      AnswerId: "answerdId",
      type: "questions",
      text: "Question Text",
      options: [
        {name: "Option 1",
        profilePicture:"https://randomuser.me/api/portraits/men/75.jpg",
        qualification: {
          trait1:"a",
          b:"b",
          c:"c"
        },
        profession: {
          profession1:"a",
          b:"b",
          c:"c"
        }},
        {name: "Option 2",
        profilePicture:"https://randomuser.me/api/portraits/men/76.jpg",
        qualification: {
          trait1:"a",
          b:"b",
          c:"c"
        },
        profession: {
          profession1:"a",
          b:"b",
          c:"c"
        }},
        {name: "Option 3",
        profilePicture:"https://randomuser.me/api/portraits/men/77.jpg",
        qualification: {
          trait1:"a",
          b:"b",
          c:"c"
        },
        profession: {
          profession1:"a",
          b:"b",
          c:"c"
        }}
      ]
    }
  }
  // const list = useSelector((state) => state.allApiCalls.startquestion);
  const massage = useSelector((state) => state.allApiCalls.errorMasssge);
  const loading = useSelector((state) => state.allApiCalls.Loading);
  const { createAnswer,onPageReload,onPageBack } = bindActionCreators(actionsCreators, dispatch);
  const VisitedId = list?.VisitId;
  const AnswerId = list?.NextQuestion?.AnswerId;

  useEffect(() => {
    // if (!authCheck()) {
    //   navigate("/");
    // }
  }, []);

  window.onload = function () {
    onPageReload();
  };

  
  async function handleClickOpen(_id) {
    const data = {
      selectedOptionId: _id,
      VisitId: VisitedId,
      AnswerId: AnswerId,
      filters: "",
    };
    try {
      const response = await createAnswer(data);
      if (response.data.isSuccess) {
        // console.log("response on page");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function pageBack() {
    try {
      const response = await onPageBack();
      if (response.data.isSuccess) {
        if(response.data.data.NextQuestion.type === "mcq"){
          navigate('/questions')
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const renderProfession = (row) => {
    for (var key in row.profession) {
      if (row.profession.hasOwnProperty(key)) {
        if (row.profession[key]) {
          return <>{key}</>;
        }
      }
    }
  };
  const renderQualification = (row) => {
    for (var key in row.qualification) {
      if (row.qualification.hasOwnProperty(key)) {
        if (row.qualification[key]) {
          return <>{key}</>;
        }
      }
    }
  };

  function removeRestoreId() {
    localStorage.removeItem("restore");
  }

  return (
    <section class="background-bg d-flex align-items-center">
      <div class="container">
        <div class="curve-box-signup afteroff d-flex align-items-center">
          <div class="close-btn">
            <Link onClick={removeRestoreId} to="/">
              <img src={close} alt="" />
            </Link>
          </div>

          <div class="nextbtn" onClick={() => navigate("/")}>
            <img src={righticon} alt="" />
          </div>

          <div onClick={() => pageBack()} class="rightbtn">
            <img src={lefticon} alt="" />
          </div>
          <div class="row w100 justify-content-center">
            <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12">
              <div class="container-fluid lastchilds">
                <div class="col-lg-12">
                  <div class="headingwithlogo">
                    <img src={whitelogos} alt="" />
                    <h6 class="mt-4">
                      There are Professionals in our network who’ll be more than
                      happy to help you{" "}
                    </h6>
                    <h3 class="mt-0 colornew">
                      Book your 15-minute chemistry session for nominal fee
                    </h3>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="row mt-5">
                    {loading ? (
                      <div className="question-spinner">
                        <Spinner animation="border" variant="light" />
                      </div>
                    ) : (
                      <>
                        {list?.NextQuestion?.options &&
                          list?.NextQuestion?.options.map((row) => (
                            <div
                              key={row.id}
                              class="col-lg-4 col-md-4 col-sm-12 disnonemobile"
                            >
                              <div class="boximagebox">
                                <img src={row.profilePicture} alt="" />
                                <div class="boximagecont">
                                  <h3>{row.name}</h3>
                                  <h5>Clinical Psychologist</h5>
                                  <p>
                                    {renderQualification(row)}
                                    {renderProfession(row)}
                                  </p>
                                  <button
                                    onClick={() => handleClickOpen(row.id)}
                                    class="btn white-border"
                                  >
                                    Learn More
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                      </>
                    )}
                      {/* <div className="errormassages">
                        <h5>{massage}</h5>
                      </div> */}
                  </div>
                </div>

                <div class="row">
                  <div class="boxtexts">
                    <p>
                      What is the difference be a Psychologist or a
                      Psychiatrist?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mute-rounds">
        <a href="#.">
          {" "}
          <img src={muteicon} alt="" /> Mute
        </a>
      </div>
    </section>
  );
}

export default Professionals;

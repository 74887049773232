import React, { useEffect } from "react";
import Roundtop from "./assets/images/round-top.png";
import close from "./assets/images/close.png";
import righticon from "./assets/images/right-icon.png";
import menulogo from "./assets/images/menu-logo.png";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../state/index";
import lefticon from "./assets/images/left-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import muteicon from "./assets/images/muteicon.png";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { authCheck } from "../utils/auth";

function Question() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const list = {
    VisitId : "visitid123",
    NextQuestion: {
      AnswerId: "answerdId",
      type: "questions",
      text: "Question Text",
      options: [
        {text: "option 1"},
        {text: "option 2"},
        {text: "option 3"}
      ]
    }
  }
  
  // useSelector((state) => state.allApiCalls.startquestion);
  const massage = useSelector((state) => state.allApiCalls.errorMasssge);
  const loading = useSelector((state) => state.allApiCalls.Loading);
  const { createAnswer, onPageReload ,onPageBack} = bindActionCreators(
    actionsCreators,
    dispatch
  );
  const VisitedId = list?.VisitId;
  const AnswerId = list?.NextQuestion?.AnswerId;

  useEffect(() => {
    // if (!authCheck()) {
    //   navigate("/");
    // }
  }, []);

  window.onload = function () {
    onPageReload();
  };

  async function handleClickOpen(_id) {
    navigate("/practitionerCriteria");
    const data = {
      selectedOptionId: _id,
      VisitId: VisitedId,
      AnswerId: AnswerId,
      filters: "",
    };
    try {
      const response = await createAnswer(data);
      if (response.data.isSuccess) {
        if (response?.data?.data?.NextQuestion?.type === "selectProfessions") {
          navigate("/practitionerCriteria", { state: { categoryId: _id } });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  function removeRestoreId() {
    localStorage.removeItem("restore");
  }

  return (
    <>
      {list?.NextQuestion?.type === "list" ? (
        navigate("/professionals")
      ) : (
        <section class="background-bg d-flex align-items-center">
          {/* <div class="image-rounds disnoneselect">
         <a href="#."> <img src={Roundtop} alt="" /></a>
     </div> */}
          <div class="container">
            <div class="curve-box-signup selecttabs d-flex align-items-center">
              <div class="close-btn">
                <Link onClick={removeRestoreId} to="/">
                  <img src={close} alt="" />{" "}
                </Link>
              </div>
              <div onClick={() => onPageBack()} class="rightbtn">
                <img src={lefticon} alt="" />
              </div>
              {/* <div class="nextbtn">
                 <img src={righticon} alt="" />
             </div> */}
              <div class="row w100 justify-content-center">
                <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12">
                  <div class="container-fluid lastchilds">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="headingwithlogo">
                          <img src={menulogo} alt="" />
                          <h3>{list?.NextQuestion?.text}</h3>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-40c">
                      {loading ? (
                        <div className="question-spinner">
                          <Spinner animation="border" variant="light" />
                        </div>
                      ) : (
                        <>
                          {list?.NextQuestion?.options &&
                            list?.NextQuestion?.options.map((row) => (
                              <div
                                key={row.id}
                                class="col-lg-6 col-md-6 col-sm-12"
                              >
                                <div
                                  onClick={() => handleClickOpen(row.id)}
                                  class="select-button"
                                >
                                  <a class="selectstyle">{row.text}</a>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                      {/* <div className="errormassages">
                        <h5>{massage}</h5>
                      </div> */}

                      {/* <div class="col-lg-12 col-md-12 col-sm-12">
                 <div class="select-button">
                   <a href="#." class="selectstyle">
                     My focus, productivity and/or communication
                   </a>
                 </div>
               </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Question;

import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import round from './assets/images/round-top.png';
import close from "./assets/images/close.png";
import righticon from "./assets/images/right-icon.png";
import menulogo from "./assets/images/menu-logo.png";
import muteicon from "./assets/images/muteicon.png";
import { Spinner } from "react-bootstrap";
import ls from "localstorage-slim";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../state/index";
import { useDispatch, useSelector } from "react-redux";
import { authCheck } from "../utils/auth";

function NameAndBirthday() {
  const navigate = useNavigate();
  const Users = ls.get("userData", { decrypt: true });
  const currentToken = localStorage.getItem("Token");
  const token = JSON.parse(currentToken);
  const userName = Users?.data.name;
  const userBday = Users?.data.dateOfBirth;
  const userId = Users?.data?.id;
  const [name, setname] = useState(userName);
  const [bday, setbday] = useState(userBday);
  const [Loading, setLoading] = useState(false);
  const location = useLocation();
  const _id = location?.state?.categoryId;
  const dispatch = useDispatch();
  const { updateUser, createVisit } = bindActionCreators(
    actionsCreators,
    dispatch
  );

  useEffect(() => {
    // if (!authCheck()) {
    //   navigate("/");
    // }
  }, []);

  const updatedData = { name: name, dateOfBirth: bday };

  async function update() {
    const data = {
        userId:userId,
        categoryId:_id,
        name:userName,
        dateOfBirth:userBday    
    };
    if (name !== "" && bday !== "") {
      try {
        const response = await updateUser(updatedData);
        if (response.data.isSuccess) {
          createVisit(data);
          navigate('/questions')
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("fiil all the feild");
    }
  }

  return (
    <section class="background-bg d-flex align-items-center">
      <div class="container">
        <div class="curve-box-signup selecttabs d-flex align-items-center">
          <Link to="/">
            <div class="close-btn">
              <a href="#.">
                <img src={close} alt="" />{" "}
              </a>
            </div>
          </Link>

          <div class="nextbtn">
            <img src={righticon} alt="" />
          </div>
          <div class="row w100 justify-content-center">
            <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12">
              <div class="container-fluid lastchilds">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="headingwithlogo">
                      <img src={menulogo} alt="" />
                      <h3>Hello! What brought you here today?</h3>
                    </div>
                    <div class="question">
                      <h5>
                        I am Halo and I am a very caring and smart search
                        engine…. <br />
                        and you are?
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="row mt-40c  justify-content-center ">
                  <div class="col-lg-8 col-md-8 col-sm-12">
                    <div class="select-button inputstyles">
                      <div class="form-group">
                        <input
                          type="text"
                          placeholder="First Name"
                          value={name}
                          onChange={(e) => setname(e.target.value)}
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="question">
                    <h5> You have been around since? </h5>
                  </div>

                  <div class="col-lg-8 col-md-8 col-sm-12">
                    <div class="select-button inputstyles">
                      <div class="form-group">
                        <input
                          type="date"
                          placeholder="dd/mm/yy"
                          value={bday}
                          onChange={(e) => setbday(e.target.value)}
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-12">
                    <div class="m-auto mmautos">
                      <button
                        class="btn btn-submit btncolor mt-4 text-white"
                        disabled={Loading}
                        onClick={update}
                      >
                        {Loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              variant="light"
                            />
                            &nbsp; Loading...
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mute-rounds">
        <a href="#.">
          {" "}
          <img src={muteicon} alt="" /> Mute
        </a>
      </div>
    </section>
  );
}

export default NameAndBirthday;

import React, { useState, useEffect } from "react";
// import round from './assets/images/round-top.png';
// import search from './assets/images/search.png';
import { Link } from "react-router-dom";
// import Video from "./assets/video/abc.mp4"
// import poster from './assets/images/background-image.jpg';
import { bindActionCreators } from "redux";
import { actionsCreators } from "../state/index";
import { useDispatch, useSelector } from "react-redux";
import { fadeInDown } from "react-animations";
import Radium, { StyleRoot } from "radium";

const currentToken = localStorage.getItem("Token");
const token = JSON.parse(currentToken);

const styles = {
  fadeInDown: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInDown, "fadeInDown"),
  },
};

function Splash() {
  const isActive = useSelector((state) => state.allApiCalls.isActive);
  const dispatch = useDispatch();
  const { isMenuActive } = bindActionCreators(actionsCreators, dispatch);


  document.body.onkeyup = function (e) {
    if (e.keyCode == 32) {
      checktoogler();
    }
  };

  function checktoogler() {
    isMenuActive(true);
  }

  return (
    <StyleRoot>
      <section class="background-bg huns d-flex align-items-center">
        <div class="container position-relative" style={styles.fadeInDown}>
          {isActive ? (
            ""
          ) : (
            <div class="splashtext">
              <h3>Hey There!</h3>
              <p>Press spacebar to talk to us</p>
            </div>
          )}
        </div>
      </section>
    </StyleRoot>
  );
}

export default Splash;

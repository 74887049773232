const initialState = {
  isActive: false,
  question: [],
  startquestion: [],
  Loading: false,
  errorMasssge: "",
};

const apiCalls = (state = initialState, action) => {
  switch (action.type) {
    case "menuActive":
      return {
        ...state,
        isActive: action.payload,
      };

    case "isLoading":
      return {
        ...state,
        Loading: action.payload,
      };

    case "firstQuestion":
      return {
        ...state,
        question: action.payload,
      };

    case "startquestion":
      return {
        ...state,
        startquestion: action.payload,
      };

    case "errorMassage":
      return {
        ...state,
        errorMasssge: action.payload,
      };

    default:
      return state;
  }
};
export default apiCalls;


const initialState = {
    userToken:{},
    userData:{},
};


const reducer = (state = initialState ,action) => {
    switch (action.type){
        case "authSignIn":
            return {...state , userToken:action.payload};

        case "SignIn":
            return {...state , userData:action.payload};

        case "SignUp":
            return {...state , userData:action.payload};

        default:
            return state
    }
}
export default reducer;
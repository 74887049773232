import React, { useState, useEffect } from "react";
import { auth } from "../../utils/init-firebase";
import { signOut } from "firebase/auth";
import Roundtop from "../assets/images/round-top.png";
import Menulogo from "../assets/images/menu-logo.png";
// import search from './assets/images/search.png';
import appstoremenu from "../assets/images/appstoremenu-btn.png";
import profile from "../assets/images/profile.png";
import mindscape from "../assets/images/mindscape-logomenu.png";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../../state/index";
import { useDispatch, useSelector } from "react-redux";
import ls from "localstorage-slim";
import { fadeInDown } from "react-animations";
import Radium, { StyleRoot } from "radium";

const styles = {
  fadeInDown: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeInDown, "fadeInDown"),
  },
};

function Header() {
  const currentToken = localStorage.getItem("Token");
  const token = JSON.parse(currentToken);
  const navigate = useNavigate();
  const value = ls.get("userData", { decrypt: true });
  const isActive = useSelector((state) => state.allApiCalls.isActive);

  const dispatch = useDispatch();
  const { isMenuActive } = bindActionCreators(actionsCreators, dispatch);

  function checktoogler() {
    isMenuActive(!isActive);
  }
  const timer = async () => {
    signOut(auth);
    ls.clear();
    localStorage.clear();
    navigate("/");
  };
  const pathname = window.location.pathname;

  return (
    <StyleRoot>
      <>
        {isActive ? (
          <div
            class="animation-check backgroundgrey pb-4"
            style={styles.fadeInDown}
          >
            <div class="spacerow">
              <div class="container">
                <div class="row d-flex justify-content-between align-items-center">
                  <div class="col-lg-3 col-md-3 col-sm-12">
                    <div
                      onClick={() => checktoogler()}
                      class="closebtn menulogo"
                    >
                      <a>
                        <img src={Roundtop} alt="" /> Menu
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-12">
                    <div class="loginbtns">
                      {value ? (
                        <button
                          onClick={async (e) => {
                            e.preventDefault();
                            await timer();
                          }}
                          class="btn white-border"
                          disabled={""}
                        >
                          Logout
                        </button>
                      ) : (
                        <>
                          <Link onClick={() => checktoogler()} to="/signin">
                            <button class="btn white-border mr-1-desk">
                              Log in
                            </button>
                          </Link>
                          <Link onClick={() => checktoogler()} to="/signup">
                            <button class="btn white-border mr-1-desk">
                              Sign-up
                            </button>
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container">
              <div class="row no-gutters">
                <div class="col-lg-9 col-md-9 col-sm-12">
                  <div class="row no-gutters">
                    <div className="for-margin col-lg-5 col-md-5 col-sm-12 col-xs-12">
                      <div class="box-bottom-href mt-f">
                        <div class="heading-para">
                          <p>
                            We are a community that ignites possibility by
                            challenging you to explore the landscapes of your
                            mind.
                          </p>
                        </div>
                        <div class="bottomhrefs">
                          <a href="#." class="colorhref">
                            Read our Manifesto of Contemporary Wellbeing
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-12">
                      <div class="row no-gutters">
                        <div class="for-margin col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div class="box-bottom-href gray-lightdark">
                            <div class="heading-logo">
                              <img src={Menulogo} alt="" />
                              <p>Let us find the right expert for you</p>
                              {token ? (
                                <Link
                                  onClick={() => checktoogler()}
                                  to="/start"
                                >
                                  <div class="loginbtns-search">
                                    <a
                                      id="btn-height"
                                      class="btn white-border mr-1-desk"
                                      href="#."
                                    >
                                      Connect with an expert
                                    </a>
                                  </div>
                                </Link>
                              ) : (
                                <Link
                                  onClick={() => checktoogler()}
                                  to="/signin"
                                >
                                  <div class="loginbtns-search">
                                    <a
                                      id="btn-height"
                                      class="btn white-border mr-1-desk"
                                      href="#."
                                    >
                                      Connect with an expert
                                    </a>
                                  </div>
                                </Link>
                              )}
                            </div>
                            <div class="bottomhrefs">
                              <a href="#." class="colorhref">
                                Join our team of experts
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="for-margin col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div class="box-bottom-href mindscapebg">
                            <div class="heading-logo mmlogo">
                              <img src={mindscape} alt="" />
                              <p>Be the best version of yourself.</p>
                            </div>
                            <div class="bottomhrefs bgnone mb-2">
                              <a href="#.">
                                {" "}
                                <img src={appstoremenu} alt="" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class=" col-lg-3 col-md-3 col-sm-12">
                  <div class="row no-gutters">
                    <div class="for-margin col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="menu-navs">
                        <ul>
                          <li>
                            <a href="#.">WE ARE</a>
                          </li>
                          <li>
                            <a href="#.">BLOG</a>
                          </li>
                          <li>
                            <a href="#.">CONTACT US</a>
                          </li>
                          <li>
                            <a href="#.">PRIVACY</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="for-margin col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="profilename-d">
                        <img src={profile} alt="" />
                        <h3 className="latter-caps">{value?.data?.name}</h3>
                        <h5>Dashboard</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <section
              class="checkingas animation-check pb-4"
              style={styles.fadeInDown}
            >
              <div onClick={() => checktoogler()} class="image-rounds">
                <a>
                  {" "}
                  <img src={Roundtop} alt="" />
                </a>
              </div>
              <div class="toprightsearch">
                {pathname === "/" ? (
                  <div class="menusearchs">
                    <div class="searchicon"></div>
                    {token ? (
                      <div class="loginbtns-search">
                        <Link to="/start">
                          <a class="btn white-border mr-1-desk" href="#.">
                            Connect with an expert
                          </a>
                        </Link>
                      </div>
                    ) : (
                      <div class="loginbtns-search">
                        <Link to="/signin">
                          <a class="btn white-border mr-1-desk" href="#.">
                            Connect with an expert
                          </a>
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </section>
          </>
        )}
      </>
    </StyleRoot>
  );
}

export default Header;

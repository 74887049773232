import "./App.css";
import "./components/assets/css/style.css";
import "./components/assets/css/responsive.css";
import "./components/assets/fonts/stylesheet.css";
import Login from "./components/login";
import Header from "./components/headers/header";
import Splash from "./components/splash";
import SignUp from "./components/signup";
import WhatboughtScreen from "./components/whatboughtScreen";
import NameAndBirthday from "./components/nameAndBirthday";
import Question from "./components/questions";
import Professionals from "./components/professionals";
import PractitionerCriteria from "./components/practitionerCriteria";
import { Routes, Route ,Navigate} from "react-router-dom";
import ls from "localstorage-slim";

function App() {
  ls.config.encrypt = true;
  const currentToken = localStorage.getItem("Token");
  const token = JSON.parse(currentToken);
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/signin" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/start" element={<WhatboughtScreen />} />
        <Route path="/name-and-birth" element={<NameAndBirthday />} />
        <Route path="/questions" element={<Question />} />
        <Route path="/professionals" element={<Professionals />} />
        <Route
          path="/practitionerCriteria"
          element={<PractitionerCriteria/>}
        />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import close from "./assets/images/close.png";
import menulogo from "./assets/images/menu-logo.png";
import muteicon from "./assets/images/muteicon.png";
import righticon from "./assets/images/right-icon.png";
import lefticon from "./assets/images/left-icon.png";
import { Link, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../state/index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { authCheck } from "../utils/auth";
import Select from "react-select";

export default function PractitionerCriteria() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.allApiCalls.startquestion);
  const loading = useSelector((state) => state.allApiCalls.Loading);
  const massage = useSelector((state) => state.allApiCalls.errorMasssge);
  const { createAnswer, onPageReload } = bindActionCreators(
    actionsCreators,
    dispatch
  );
  const [gender, setgender] = useState([]);
  const [language, setlanguage] = useState([]);
  const [nationality, setnationality] = useState([]);
  const [priceRange, setpriceRange] = useState("");
  const [availibility, setavailibility] = useState("");
  const [spirituality, setspirituality] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // if (!authCheck()) {
    //   navigate("/");
    // }
  }, []);

  window.onload = function () {
    onPageReload();
  };
  function removeRestoreId() {
    localStorage.removeItem("restore");
  }

  const VisitedId = list?.VisitId;
  const AnswerId = list?.NextQuestion?.AnswerId;
  const location = useLocation();
  const _id = location?.state?.categoryId;

  const getValues = (arr) => {
    let values = [];
    for (let i = 0; i < arr?.length; i++) {
      values.push(arr[i].value);
    }
    return values;
  };

  const newData = {
    gender: getValues(gender),
    religion: [spirituality?.value],
    language: getValues(language),
    priceRange: [priceRange?.value],
    availibility: [availibility?.value],
    nationality: getValues(nationality),
  };

  const genders = [
    { label: "Male ", value: "Male" },
    { label: "Female ", value: "Female" },
    { label: "Non-binary ", value: "Non-binary" },
  ];
  const languages = [
    { label: "Arabic ", value: "Arabic" },
    { label: "English ", value: "English" },
    { label: "French ", value: "French" },
  ];
  const price = [
    { label: "50-100 USD/session ", value: "50-100 USD/session" },
    { label: "100-200 USD/session ", value: "100-200 USD/session" },
    { label: "200USD/session ", value: "200USD/session" },
  ];
  const Availibilities = [
    { label: "Monday ", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday ", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];
  const spiritualities = [
    { label: "Atheist ", value: "Atheist" },
    { label: "Buddhism", value: "Buddhism" },
    { label: "Christianity", value: "Christianity" },
    { label: "Hinduism ", value: "Hinduism" },
    { label: "Islam", value: "Islam" },
    { label: "Judaism", value: "Judaism" },
  ];
  const contries = [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "land Islands", label: "land Islands" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "AndorrA", label: "AndorrA" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antarctica", label: "Antarctica" },
    { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Bouvet Island", label: "Bouvet Island" },
    { value: "Brazil", label: "Brazil" },
    {
      value: "British Indian Ocean Territory",
      label: "British Indian Ocean Territory",
    },
    { value: "Brunei Darussalam", label: "Brunei DarussalamBN" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Cape Verde", label: "Cape Verde" },
    { value: "Cayman Islands", label: "Cayman Islands" },
    { value: "Central African Republic", label: "Central African Republic" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Christmas Island", label: "Christmas Island" },
    { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo", label: "Congo" },
    {
      value: "Congo, The Democratic Republic of the",
      label: "Congo, The Democratic Republic of the",
    },
    { value: "Cook Islands", label: "Cook Islands" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Cote D", label: "Cote D" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Ethiopia", label: "Ethiopia" },
    {
      value: "Falkland Islands (Malvinas)",
      label: "Falkland Islands (Malvinas)",
    },
    { value: "Faroe Islands", label: "Faroe Islands" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "French Guiana", label: "French Guiana" },
    { value: "French Polynesia", label: "French Polynesia" },
    {
      value: "French Southern Territories",
      label: "French Southern Territories",
    },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Gibraltar", label: "Gibraltar" },
    { value: "Greece", label: "Greece" },
    { value: "Greenland", label: "Greenland" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Guam", label: "Guam" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    {
      value: "Heard Island and Mcdonald Islands",
      label: "Heard Island and Mcdonald Islands",
    },
    {
      value: "Holy See (Vatican City State)",
      label: "Holy See (Vatican City State)",
    },
    { value: "Honduras", label: "Honduras" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran, Islamic Republic Of", label: "Iran, Islamic Republic Of" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Isle of Man", label: "Isle of Man" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jersey", label: "Jersey" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Korea, Democratic People", label: "Korea, Democratic People" },
    { value: "Korea, Republic of", label: "Korea, Republic of" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    { value: "Lao People", label: "Lao People" },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Macao", label: "Macao" },
    {
      value: "Macedonia, The Former Yugoslav Republic of",
      label: "Macedonia, The Former Yugoslav Republic of",
    },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Martinique", label: "Martinique" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mayotte", label: "Mayotte" },
    { value: "Mexico", label: "Mexico" },
    {
      value: "Micronesia, Federated States of",
      label: "Micronesia, Federated States of",
    },
    { value: "Moldova, Republic of", label: "Moldova, Republic of" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar", label: "Myanmar" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "Netherlands Antilles", label: "Netherlands Antilles" },
    { value: "New Caledonia", label: "New Caledonia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Niue", label: "Niue" },
    { value: "Norfolk Island", label: "Norfolk Island" },
    { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    {
      value: "Palestinian Territory, Occupied",
      label: "Palestinian Territory, Occupied",
    },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Pitcairn", label: "Pitcairn" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Puerto Rico", label: "Puerto Rico" },
    { value: "Qatar", label: "Qatar" },
    { value: "Reunion", label: "Reunion" },
    { value: "Romania", label: "Romania" },
    { value: "Russian Federation", label: "Russian Federation" },
    { value: "RWANDA", label: "RWANDA" },
    { value: "Saint Helena", label: "Saint Helena" },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
    {
      value: "Saint Vincent and the Grenadines",
      label: "Saint Vincent and the Grenadine",
    },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    {
      value: "South Georgia and the South Sandwich Islands",
      label: "South Georgia and the South Sandwich Islands",
    },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
    { value: "Swaziland", label: "Swaziland" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
    { value: "Taiwan, Province of China", label: "Taiwan, Province of China" },
    { value: "Tajikistan", label: "Tajikistan" },
    {
      value: "Tanzania, United Republic of",
      label: "Tanzania, United Republic of",
    },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-Leste", label: "Timor-Leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
    {
      value: "United States Minor Outlying Islands",
      label: "United States Minor Outlying Islands",
    },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Viet Nam", label: "Viet Nam" },
    { value: "Virgin Islands, British", label: "Virgin Islands, British" },
    { value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
    { value: "Wallis and Futuna", label: "Wallis and Futuna" },
    { value: "Western Sahara", label: "Western Sahara" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" },
  ];

  async function handleClickOpen() {
    navigate("/professionals");
    const data = {
      selectedOptionId: _id,
      VisitId: VisitedId,
      AnswerId: AnswerId,
      filters: newData,
    };
    try {
      const response = await createAnswer(data);
      if (response.data.isSuccess) {
        navigate("/professionals");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <section class="background-bg d-flex align-items-center">
      <div class="container">
        <div class="curve-box-signup selecttabs d-flex align-items-center">
          <Link onClick={removeRestoreId} to="/">
            <div class="close-btn">
              <a href="#.">
                <img src={close} />{" "}
              </a>
            </div>
          </Link>

          <div onClick={handleClickOpen} class="nextbtn">
            {loading ? (
              <>
                <Spinner animation="border" variant="light" />
              </>
            ) : (
              <>
                <img src={righticon} alt="" />
              </>
            )}
          </div>

          {/* <div class="rightbtn">
                        <img src={lefticon} />
                    </div> */}
          <div class="row w100 justify-content-center">
            <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12">
              <div class="container-fluid lastchilds">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="headingwithlogo">
                      <img src={menulogo} />
                      <h3>Practitioner’s Criteria</h3>
                    </div>
                  </div>
                </div>
                <div class="row mt-40c justify-content-center d-flex">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row justify-content-center d-flex">
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        {/* <div className="errormassages">
                          <h5>{massage}</h5>
                        </div> */}
                        <div class="select-button">
                          <Select
                            className="multiselect"
                            placeholder="Gender"
                            value={gender}
                            onChange={setgender}
                            options={genders}
                            isMulti
                          />
                          {/* <select
                            onChange={(e) => setgender(e.target.value)}
                            name="options"
                          >
                            <option
                              selected="true"
                              disabled="disabled"
                              value=""
                            >
                              Gender
                            </option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                            <option value="Non-binary">Non-binary</option>
                          </select> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row justify-content-center d-flex">
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="select-button">
                          <div class="roundiconsselect"></div>
                          <Select
                            className="multiselect"
                            placeholder="Language"
                            value={language}
                            onChange={setlanguage}
                            options={languages}
                            isMulti
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row justify-content-center d-flex">
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="select-button">
                          <div class="roundiconsselect"></div>
                          <Select
                            className="multiselect"
                            placeholder="Nationality"
                            value={nationality}
                            onChange={setnationality}
                            options={contries}
                            isMulti
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row justify-content-center d-flex">
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="select-button">
                          <div class="roundiconsselect"></div>
                          <Select
                            className="multiselect"
                            placeholder="Price Range"
                            value={priceRange}
                            onChange={setpriceRange}
                            options={price}
                            // styles={customStyles}
                            // isMulti
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row justify-content-center d-flex">
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="select-button">
                          <div class="roundiconsselect"></div>
                          <Select
                            className="multiselect"
                            placeholder="Availibility"
                            value={availibility}
                            onChange={setavailibility}
                            options={Availibilities}
                            // styles={customStyles}
                            // isMulti
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row justify-content-center d-flex">
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <div class="select-button">
                          <div class="roundiconsselect"></div>
                          <Select
                            className="multiselect"
                            placeholder="Spirituality"
                            value={spirituality}
                            onChange={setspirituality}
                            options={spiritualities}
                            // styles={customStyles}
                            // isMulti
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mute-rounds">
        <a href="#.">
          {" "}
          <img src={muteicon} /> Mute
        </a>
      </div>
    </section>
  );
}
